import config from '../../config'
import { authHeader } from '../helpers/auth_header';

export default class userService {

    login(username, password) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password })
        };

        return fetch(`${config.iwib.server}/users/authenticate`, requestOptions)
            .then(handleResponse)
            .then(user => {
                // login successful if there's a jwt token in the response
                if (user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user));
                }

            return user;
        });
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('user');
    }

    register(user) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(user)
        };

        return fetch(`${config.iwib.server}/users/register`, requestOptions).then(handleResponse);
    }

    getAll() {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        return fetch(`${config.iwib.server}/users`, requestOptions).then(handleResponse);
    }


    getById(id) {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        return fetch(`${config.iwib.server}/users/${id}`, requestOptions).then(handleResponse);
    }

    update(user) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(user)
        };

        return fetch(`${config.iwib.server}/users/${user.id}`, requestOptions).then(handleResponse);
    }

    // prefixed function name with underscore because delete is a reserved word in javascript
    _delete(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: authHeader()
        };

        return fetch(`${config.iwib.server}/users/${id}`, requestOptions).then(handleResponse);
    }
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
