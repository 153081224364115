/*
    IWI 2
    Julien@cit 
    Feb. 2021
*/

/* ROUTES */
import { createRouter, createWebHistory } from "vue-router"

const routes = [
  { path: "/", name: "home", component: () => import("@/vues/home") },
  {
    path: "/piekirpoint/:line/:point",
    name: "piekirpoint",
    component: () => import("@/vues/piekirpoint"),
  },
  {
    path: "/tjaliepoint/:line/:point",
    name: "tjaliepoint",
    component: () => import("@/vues/tjaliepoint"),
  },
  {
    path: "/hash",
    name: "hash",
    component: () => import("@/vues/hash"),
  },
  {
    path: "/aangespoeld",
    name: "aangespoeld_general",
    component: () => import("@/vues/aangespoeld"),
  },
  {
    path: "/aangespoeld/:recordTitle",
    name: "aangespoeld",
    component: () => import("@/vues/aangespoeld"),
  },
  {
    path: "/eternite",
    name: "eternite_general",
    component: () => import("@/vues/eternite"),
  },
  {
    path: "/eternite/:recordTitle",
    name: "eternite",
    component: () => import("@/vues/eternite"),
  },
  {
    path: "/collectie",
    name: "collectie_general",
    component: () => import("@/vues/collectie"),
  },
  {
    path: "/collectie/:recordId",
    name: "collectie",
    component: () => import("@/vues/collectie"),
  },
  { path: "/tjalie", name: "tjalie_general", component: () => import("@/vues/tjalie") },
  { path: "/tjalie/:line/:point", name: "tjalie_back", component: () => import("@/vues/tjalie") },
  { path: "/piekirpedia", name: "piekirpedia_general", component: () => import("@/vues/piekirpedia") },
  { path: "/piekirpedia/:line/:point", name: "piekirpedia_back", component: () => import("@/vues/piekirpedia") },
  //{ path: "/piekirpedia/:recordTitle", name: "piekirpedia", component: () => import("@/vues/piekirpedia") },
  { path: "/login", name: "login", component: () => import("@/vues/login") },
  {
    path: "/admin/users",
    name: "adminUsers",
    component: () => import("@/vues/admin/users"),
  },
  {
    path: "/admin/aangespoeld",
    name: "adminAangespoeld",
    component: () => import("@/vues/admin/aangespoeld"),
  },
  {
    path: "/admin/tjalie",
    name: "adminTjalie",
    component: () => import("@/vues/admin/tjalie"),
  },
  {
    path: "/admin/piekirpedia",
    name: "adminPiekirpedia",
    component: () => import("@/vues/admin/piekirpedia"),
  },
  {
    path: "/admin/eternite",
    name: "adminEternite",
    component: () => import("@/vues/admin/eternite"),
  },
  {
    path: "/admin/tjalie_points",
    name: "adminTjaliePoints",
    component: () => import("@/vues/admin/tjalie_points"),
  },
  {
    path: "/admin/piekir_points",
    name: "adminPiekirPoints",
    component: () => import("@/vues/admin/piekir_points"),
  },
  {
    path: "/:pathMatch(.*)",
    name: "404",
    component: () => import("@/vues/404"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const authRequired = to.path.includes("/admin/")
  const loggedIn = localStorage.getItem("user")

  if (authRequired && !loggedIn) {
    return next("/login")
  }

  next()
})

/* PRIMEVUE was */
// before 04/05/2022 ^3.3.5
// 04/05/2022 ^3.12.6
import PrimeVue from "primevue/config"
import InputMask from "primevue/inputmask"
import Button from "primevue/button"
import Sidebar from "primevue/sidebar"
import Toast from "primevue/toast"
import ToastService from "primevue/toastservice"
import DataTable from "primevue/datatable"
import Column from "primevue/column"
import Toolbar from "primevue/toolbar"
import Card from "primevue/card"
import Textarea from "primevue/textarea"
import InputText from "primevue/inputtext"
import Dialog from "primevue/dialog"
import MultiSelect from "primevue/multiselect"
import MenuBar from "primevue/menubar"
import FileUpload from "primevue/fileupload"
import Dropdown from "primevue/dropdown"
import Panel from "primevue/panel"
import Menu from "primevue/menu"
import Carousel from "primevue/carousel"
import OverlayPanel from "primevue/overlaypanel"
import ColorPicker  from "primevue/colorpicker"
import RadioButton  from "primevue/radiobutton"
import Checkbox from "primevue/checkbox"
import ConfirmationService from 'primevue/confirmationservice'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Tooltip from 'primevue/tooltip'

import "primeflex/primeflex.css"
import "primevue/resources/themes/saga-blue/theme.css"
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"

/* GLOBAL CONFIGURATION */
//import * as Plugins from "../plugins/plugins"

/* OTHER COMPONENTS */
import InlineSvg from "vue-inline-svg"
import mitt from "mitt"
import { VueMasonryPlugin } from "vue-masonry/src/masonry.plugin"
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import BlotFormatter from 'quill-blot-formatter'

//import { ImageResize } from 'quill-image-resize-module'
//QuillEditor.register('modules/imageResize', ImageResize)

//import CKEditor from '@ckeditor/ckeditor5-vue'
/*import Editor from '@tinymce/tinymce-vue'*/
 

/* STATE */
import { createStore } from "vuex"
import "es6-promise/auto"

const store = createStore({
  state() {
    return {
      authenticated: Boolean(localStorage.getItem("user")),
    }
  },
  mutations: {
    checkAuthentication(state) {
      state.authenticated = Boolean(localStorage.getItem("user"))
    },
  },
})

/* APPLICATION */
import { createApp } from "vue"
const emitter = mitt()
import App from "./vues/main"
import config from "../config"
//import '@/styles/main.css'

const app = createApp(App)
app.config.globalProperties.emitter = emitter
app.config.globalProperties.$publicPath = process.env.BASE_URL
app.config.globalProperties.$config = config

app.use(router)
app.use(PrimeVue)
app.use(ToastService)
app.use(store)
app.use(ConfirmationService)
app.use(VueMasonryPlugin)

app.component("Dropdown", Dropdown)
app.component("InputMask", InputMask)
app.component("Button", Button)
app.component("Sidebar", Sidebar)
app.component("Toast", Toast)
app.component("InputText", InputText)
app.component("Checkbox", Checkbox )
app.component("MenuBar", MenuBar)
app.component("Card", Card)
app.component("Dialog", Dialog)
app.component("DataTable", DataTable)
app.component("Column", Column)
app.component("MultiSelect ", MultiSelect)
app.component("Toolbar", Toolbar)
app.component("Textarea", Textarea)
app.component("FileUpload", FileUpload)
app.component("Panel", Panel)
app.component("Menu", Menu)
app.component("Carousel", Carousel)
app.component("OverlayPanel", OverlayPanel)
app.component("InlineSvg", InlineSvg)
app.component("QuillEditor", QuillEditor)
app.component("BlotFormatter", BlotFormatter)
app.component("ColorPicker", ColorPicker )
app.component("RadioButton", RadioButton)
app.component("TabView", TabView )
app.component("TabPanel", TabPanel)
app.directive('tooltip', Tooltip)
app.mount("#app")
