const env = process.env.NODE_ENV

const development = {
  mapbox: {
    token:
      "pk.eyJ1IjoiamhmZWxlY3RyaWMiLCJhIjoiY2s5Y2w1aWxpMDN1cTNscjNhbnVyeXV3dyJ9.aNc2JuskzFcD_fjSkPwyIw",
    style: "mapbox://styles/jhfelectric/ckkdhuvp50b4817my44r9ohmu",
  },
  iwib: {
    server: "http://localhost:4000",
  },
  images: {
    server: "http://localhost:4000",
  },
  url_base: ""
}

const production = {
  mapbox: {
    token:
      "pk.eyJ1IjoiamhmZWxlY3RyaWMiLCJhIjoiY2s5Y2w1aWxpMDN1cTNscjNhbnVyeXV3dyJ9.aNc2JuskzFcD_fjSkPwyIw",
    style: "mapbox://styles/jhfelectric/ckkdhuvp50b4817my44r9ohmu",
  },
  iwib: {
    server: "https://dev.cithosting.nl/iwiservice",
  },
  images: {
    server: "https://dev.cithosting.nl/iwiservice",
  },
  url_base: ""
}

const config = {
  development,
  production,
}


module.exports = config[env]
